import React from 'react';
import logo from './logo.svg';
import './App.css';
import Title from './Title';
import Subtitle from './Subtitle';

function App() {
  return (
    <div className="App">
      <head>
      <link rel="preconnect" href="https://fonts.googleapis.com"/>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous"/>
      <link href="https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap" rel="stylesheet"/>
      </head>
      <div className='section'>
      <Title/>
      <Subtitle/>
      </div>
      
    
    </div>
  );
}

export default App;
